import { useOktaAuth } from '@okta/okta-react';
import { useEffect } from 'react';
import { useCookieListener, doSetOktaAccessTokenCookie } from './utils';
import { BusyIndicator } from '@3mundi/smartscript-core';

export const SetCookie = (): JSX.Element => {
	const { authState, oktaAuth } = useOktaAuth();
	const cookieIsSet = useCookieListener();

	useEffect(() => {
		(async (): Promise<void> => {
			if (authState) {
				if (!authState.isAuthenticated) {
					return await oktaAuth.signInWithRedirect();
				}

				if (!cookieIsSet) {
					doSetOktaAccessTokenCookie(authState, oktaAuth);
				}
			}
		})();
	}, [authState, oktaAuth, cookieIsSet]);

	if (!authState) {
		return <BusyIndicator label="Please wait" />;
	}

	return <></>;
};
