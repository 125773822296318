import type { OktaAuthOptions } from '@okta/okta-auth-js';
import type { FC } from 'react';
import { OktaAuth } from '@okta/okta-auth-js';
import { LoginCallback, Security } from '@okta/okta-react';
import Home from './pages/Home';
import { SetCookie } from './pages/SetCookies';
import { ThemeProvider } from '@emotion/react';
import { theme } from './Styles/Theme';
import { doNothing } from '@3mundi/smartscript-core';

type AppProps = {
	config: OktaAuthOptions
}

const App: FC<AppProps> = ({ config }) => {
	const oktaAuth = new OktaAuth(config);

	return (
		<Security oktaAuth={oktaAuth} restoreOriginalUri={doNothing}>
			<ThemeProvider theme={theme}>
				<Home/>
				<LoginCallback/>
				<SetCookie/>
			</ThemeProvider>
		</Security>
	);
};

export default App;
