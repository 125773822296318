import type { AuthState, OktaAuth } from '@okta/okta-auth-js';
import { useState, useEffect } from 'react';

type SetCookieOptions = {
	cookieName: string
	token: string,
	maxAge: number
	path?: string
}

export const ACCESS_TOKEN = 'protas_access_token';

const ROUND_TO_ONE_THOUSAND = 1000;

export const setCookie = ({ cookieName, token, maxAge, path = '/' }: SetCookieOptions): void => {
	document.cookie = `${cookieName}=${token};max-age=${maxAge};domain=fcmlabsmartscripts.com;path=${path};SameSite=None;Secure;`;
};

export const deleteCookie = (cookieName: string): void => setCookie({ cookieName, token: '', maxAge: 0 });

export const getCookie = (cookieName: string): string | undefined => {
	return document.cookie.match('(^|;)\\s*' + cookieName + '\\s*=\\s*([^;]+)')?.pop();
};

export const calculateMaxAgeFromExpiresAt = (expiresAt: number): number => {
	return expiresAt - Math.floor(Date.now() / ROUND_TO_ONE_THOUSAND);
};

export const getOktaAccessTokenCookie = (): ReturnType<typeof getCookie> => {
	return getCookie(ACCESS_TOKEN);
};

export const checkOktaAccessTokenCookieIsSet = (): boolean => {
	return !!getOktaAccessTokenCookie();
};

export const setOktaAccessTokenCookie = (expiresAt: number, token: string): ReturnType<typeof setCookie> => {
	const maxAge = calculateMaxAgeFromExpiresAt(expiresAt);

	return setCookie({ cookieName: ACCESS_TOKEN, token, maxAge });
};

export const doSetOktaAccessTokenCookie = (authState: AuthState, oktaAuth: OktaAuth): void => {
	const token = oktaAuth.getAccessToken();

	if (!token) {
		return undefined;
	}

	if (authState && authState.accessToken) {
		return setOktaAccessTokenCookie(authState.accessToken.expiresAt, token);
	}
};

const FIVE_HUNDRED_MILLISECONDS = 500;

export const useCookieListener = (): boolean => {
	const [cookieIsSet, setCookieIsSet] = useState(() => checkOktaAccessTokenCookieIsSet());

	useEffect(() => {
		const nextState = !cookieIsSet;

		const intervalID = setInterval(() => {
			if (checkOktaAccessTokenCookieIsSet() === nextState) {
				setCookieIsSet(nextState);
			}
		}, FIVE_HUNDRED_MILLISECONDS);

		return () => {
			clearInterval(intervalID);
		};
	}, [cookieIsSet]);

	return cookieIsSet;
};
