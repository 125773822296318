import { BusyIndicator } from '@3mundi/smartscript-core';
import type OktaAuth from '@okta/okta-auth-js';
import { useOktaAuth } from '@okta/okta-react';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { H3Alert, pleaseOpenInAmadeusMessage } from './LoginAlerts';

type LoginButtonsComponentProps = {
	oktaAuth: OktaAuth,
	autoRedirect: boolean,
	setAutoredirect: React.Dispatch<React.SetStateAction<boolean>>
};

const LoginButtonsComponent: FC<LoginButtonsComponentProps> = ({
	oktaAuth,
	autoRedirect,
	setAutoredirect,
}): JSX.Element => {
	const login = async (): Promise<void> => await oktaAuth.signInWithRedirect();
	const logout = async (): Promise<boolean> => await oktaAuth.signOut();
	const toggleAutoRedirect = (): void => setAutoredirect(!autoRedirect);

	return (
		<div>
			<button onClick={login}>Login</button>
			<button onClick={logout}>Logout</button>
			<button onClick={toggleAutoRedirect}>Auto redirect: {autoRedirect.toString()}</button>
		</div>
	);
};

type HomeProps = {
	setAutoRedirect?: boolean,
};

const Home: FC<HomeProps> = ({ setAutoRedirect = true }) => {
	const [autoRedirect, setAutoredirect] = useState<boolean>(setAutoRedirect);

	const { authState, oktaAuth } = useOktaAuth();

	const isAuthenticated = authState && authState.isAuthenticated;

	useEffect(() => {
		if (isAuthenticated) {
			window.close();
		}
	}, [isAuthenticated, oktaAuth]);

	if (isAuthenticated) {
		return autoRedirect ?
			<H3Alert severity="success">{pleaseOpenInAmadeusMessage}</H3Alert> :
			<LoginButtonsComponent oktaAuth={oktaAuth} autoRedirect={autoRedirect} setAutoredirect={setAutoredirect} />;
	}

	return <BusyIndicator label="Loading..." />;
};

export default Home;
